import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout.jsx'
import LayoutWithSideMenu from '../components/LayoutWithSideMenu'
import Container from '../components/Container'
import ServicesList from '../components/ServicesList'

export default ({ data }) => {
  const services = data.allMarkdownRemark.edges.map(edge => edge.node.frontmatter)
  const breadcrumbs = [
    {
      name: 'Главная',
      path: '/'
    },
    {
      name: 'Услуги',
      path: '/uslugi'
    }
  ]
  return (
    <Layout>
      <Container>
        <Helmet>
          <title>Услуги АлексКомп по ремонту и настройке компьютеров</title>
          <meta name="description" content="Перечень услуг АлексКомп. Список наиболее распространенных работ по ремонту и настройке компьютеров. Экспертные решения и высокий уровень обслуживания." />
        </Helmet>
        <LayoutWithSideMenu heading="Услуги «АлексКомп»" menu={services} breadcrumbs={breadcrumbs}>
          <p>В спектр услуг нашей компании входят все виды ремонта компьютеров: от замены кнопки на клавиатуре до перепайки основных элементов материнских плат, таких как чипы видеокарт или северные/южные мосты. Чтобы ознакомиться с какой-либо из наших услуг, условиями ее предоставления, стоимостью и предоставляемой гарантией, вам достаточно перейти по соответствующей ссылке:</p>
          <ServicesList services={services} />
          <p>Опыт и профессиональные навыки наших специалистов позволяют устранять самые сложные и нестандартные поломки и неисправности ноутбуков любых брендов и моделей. 95% аппаратов, попадающих в руки мастеров АлексКомп, удается вернуть к жизни. Если вашему компьютеру необходима помощь – привозите его к нам в офис, и эксперты решат любые проблемы.</p>
          <p>Мы регулярно производим контроль качества предоставляемых услуг. Все наши эксперты проходят обязательную профессиональную проверку на знание специфики каждой конкретной модели ноутбука. Именно это и позволяет АлексКомпу гарантировать своим клиентам качественную работу и поддерживать уровень обслуживания на стабильно высоком уровне.</p>
        </LayoutWithSideMenu>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query getServices {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } },
      sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          frontmatter {
            name
            path
            list_description
            thumb {
              publicURL
            }
          }
        }
      }
    }
  }
`
