import React from 'react'
import Link from '../../components/Link'
import styles from './ServicesList.module.css'

export default ({ services=[] }) => {
  return (
    (services.length) && <ul className={styles['ServicesList']}>
      {services.map((service, index) =>
        <li key={index} className={styles['ServicesList__Item']}>
          <div className={styles['ServicesList__Thumb']}>
            <img src={service.thumb && service.thumb.publicURL} alt={service.name} />
          </div>
          <div className={styles['ServicesList__Content']}>
            <Link className={styles['ServicesList__Link']} to={service.path}>{service.name}</Link>
            <p className={styles['ServicesList__Description']}>{service.list_description}</p>
          </div>
        </li>
      )}
    </ul>
  )
}
